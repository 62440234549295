<template>
	<v-app>
		<loading :parentLoading="$store.getters.isLoading" />
		<app-header />
		<v-main :style="$route.path === '/top' ? 'padding-left:50vw' : ''">
			<cesium-container />
		</v-main>
		<router-view />
		<v-snackbar v-for="(snackbar, index) in snackbars" :z-index="aboveOverlayIndex" :key="snackbar.id"
			v-model="snackbar.showing" :timeout="-1" location="top right" :color="snackbar.color" rounded="pill"
			:style="`top: ${notification_top_list[index]}px;`" class="text-pre-wrap" content-class="snackbar-contents"
			:ref="snackbar.id">
			<template v-slot:text>
				<span class="ml-5" style="color: white" v-text="snackbar.text"></span>
			</template>
			<template v-slot:actions>
				<v-btn icon @click="close_snackbar(snackbar)">
					<v-icon color="white">mdi-check</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<v-dialog v-model="notificationPopup.isShow" max-width="500px" :z-index="aboveOverlayIndex" :scrim="false">
			<notification-popup :isShow="notificationPopup.isShow" :title=notificationPopup.title
				:message=notificationPopup.message :buttonText=notificationPopup.buttonText
				@close="updateNotificationPopup(false)"></notification-popup>
		</v-dialog>
	</v-app>
</template>

<script>
import Loading from "@/components/common/LoadingOverLay.vue";
import { mapMutations, mapState } from "vuex";
import AppHeader from "./components/AppHeader.vue";
import CesiumContainer from "./components/CesiumContainer.vue";
import NotificationPopup from "./components/common/NotificationPopup.vue";
import { startWorker } from "./utils/cesium-common";
import localDb from "./utils/local-db";
import * as wsConvertToPointCloudCRS from "./utils/wsConvertToPointCloudCRS";

export default {
	name: "App",
	components: {
		AppHeader,
		CesiumContainer,
		Loading,
		NotificationPopup,
	},
	async beforeCreate() {
		window.CESIUM_BASE_URL = CESIUM_BASE_URL;
		window.debugFlag = DEBUG_FLAG;
		window.featureFlag = FEATURE_FLAG;
		await this.$store.dispatch("getUser");
	},
	mounted() {
		// HACK: ダイアログのステータスはstore等vueの機能を使用して管理すべきだが他の実装と揃えるため一旦イベント発火方式にする
		window.addEventListener("openNotificationPopup", (event) =>
			this.updateNotificationPopup(true, event),
		);

		window.onbeforeunload = () => {
			// clear cookie
			document.cookie =
				"login_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		};

		const wsServerUrl =
			import.meta.env.VITE_WEB_SOCKET_URL + "/wsPointCloudTransformer";

		wsConvertToPointCloudCRS.initWebSocket(wsServerUrl);
		startWorker();
		localDb.setUpDB();
	},
	data: () => ({
		code: null,
		session_state: null,
		notification_top_list: [],
		aboveOverlayIndex: 10000, // v-loadingのz-indexより優先する
		notificationPopup: {
			isShow: false,
			title: undefined,
			message: undefined,
			buttonText: undefined,
		},
	}),
	computed: {
		lang: function () {
			return this.$store.getters["lang"];
		},
		...mapState(["snackbars"]),
	},
	watch: {
		lang() {
			this.$nextTick(() => {
				this.$i18n.locale = this.$store.state.lang;
			});
		},
		snackbars: {
			handler() {
				if (this.notification_top_list.length != this.snackbars.length) {
					this.notification_top_list = [];
					for (let i = 0; i < this.snackbars.length; i++) {
						let top = 0;
						for (let j = 0; j < i; j++) {
							top +=
								this.$refs[this.snackbars[j].id][0].contentEl.clientHeight + 10;
						}
						this.notification_top_list.push(top);
					}
				}
			},
			deep: true,
		},
	},
	methods: {
		...mapMutations(["clear_snackbars"]),
		close_snackbar(snackbar) {
			snackbar.showing = false;
			this.clear_snackbars();
		},
		updateNotificationPopup(isShow, $event) {
			if (isShow) {
				// 表示
				this.notificationPopup.isShow = true;
				this.notificationPopup.title = $event.detail.title;
				this.notificationPopup.message = $event.detail.message;
				this.notificationPopup.buttonText = $event.detail.buttonText;
			} else {
				// 非表示
				this.notificationPopup.isShow = false;
				this.notificationPopup.title = undefined;
				this.notificationPopup.message = undefined;
				this.notificationPopup.buttonText = undefined;
			}
		},
	},
};
</script>

<style></style>
