<template>
	<v-card class="justify-center" width="100%">
		<v-card-title class="d-flex align-center justify-space-between">
			{{ $t("CQW0020900001") }}
			<v-btn icon variant="flat" size="small" @click="close(false)">
				<v-icon> mdi-close </v-icon>
			</v-btn>
		</v-card-title>
		<v-tabs fixed-tabs color="primary" v-model="tab">
			<v-tab value="tab1">{{ $t("CQW0020900002") }} </v-tab>
			<v-tab value="tab2">{{ $t("UPLOAD_LOCALIZATION_FILE") }} </v-tab>
		</v-tabs>
		<!-- 座標系選択タブ -->
		<v-card-text v-show="tab === 'tab1'" class="mx-auto fill-height fluid content">
			<!-- 座標-->
			<v-row>
				<v-col>
					{{ $t("COORDINATE_SYSTEM") }}
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-autocomplete :label="$t('SELECT_COORDINATE_SYSTEM')" single-line clearable variant="outlined"
						:items="EPSG_code_items" v-model="EPSG_code" item-title="label" return-object hide-no-data hide-selected
						required></v-autocomplete>
				</v-col>
			</v-row>
			<!-- 鉛直変位 -->
			<v-row>
				<v-col>
					{{ $t("VERTICAL_DATUM") }}
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-autocomplete :label="$t('SELECT_VERTICAL_SHIFT')" single-line clearable :disabled="isSelected3dEpsg"
						variant="outlined" :items="GEO_code_items" v-model="vShift" item-title="label" return-object hide-no-data
						hide-selected required></v-autocomplete>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="text-right">
					<v-btn :disabled="isbtn2Disabled" color="primary" rounded @click="close(true)">
						{{ $t("Confirm") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card-text>
		<!-- ファイルアップロードタブ -->
		<v-card-text v-if="tab === 'tab2'" class="mt-0 fill-height fluid content mx-auto">
			<upload-localization-file :siteId="siteId" @upload="close"></upload-localization-file>
		</v-card-text>
	</v-card>
</template>

<script>
import * as lockUtils from "@/utils/lock-utils";
import axios from "axios";
import { mapMutations } from "vuex";
import UploadLocalizationFile from "./UploadLocalizationFile.vue";

export default {
	components: { UploadLocalizationFile },
	name: "LocalFileUpload",
	props: {
		project: Object,
	},
	data() {
		return {
			tab: "tab1",
			localFile: undefined,
			firstDialog: true,
			confirmDialog: false,
			EPSG_code: null,
			vShift: null,
			EPSG_code_items: [],
			GEO_code_items: [],
			showDashboardLocalizationData: false,
			dashboardInfo: {
				type: "",
				horizontal: "",
				vertical: "",
				obliqueStereographic: {},
			},
		};
	},
	computed: {
		/**
		 * 現在開いているページがプロジェクトページか判定
		 */
		isProjectPage() {
			return this.$route.name === "project";
		},
		/**
		 * 現場IDを取得
		 * 開いているページがプロジェクトページの場合はURLから現場IDを取得
		 */
		siteId() {
			if (this.isProjectPage) return this.$route.query.siteId;
			else return this.project.site_id;
		},
		isLoading: {
			get() {
				return this.$store.getters.isLoading;
			},
			set(newValue) {
				this.$store.dispatch("updateIsLoading", newValue);
			},
		},
		isSelected3dEpsg() {
			// 3dのEPSGを選択中の場合TRUE
			return this.EPSG_code !== null && this.EPSG_code.is_3d;
		},
		isbtn1Disabled() {
			// Dashboard連携タブのボタン制御
			if (this.dashboardInfo.type) {
				return false;
			} else return true;
		},
		isbtn2Disabled() {
			// 座標系選択タブのボタン制御
			if (this.isSelected3dEpsg) {
				return false;
			} else if (this.EPSG_code !== null && this.vShift !== null) {
				return false;
			} else return true;
		},
	},
	watch: {
		tab() {
			this.onTabSwitch();
		},
		isSelected3dEpsg(newValue) {
			// 3dのEPSGが選択されたら垂直基準はリセットする
			if (newValue) this.vShift = null;
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		async close(isSave, saveData) {
			this.firstDialog = true;
			this.confirmDialog = false;
			this.showDashboardLocalizationData = false;

			let uploadedData = undefined;
			const params = {
				updated_by: this.$store.state.user?.id,
				rotation: "",
				origin_easting: "",
				origin_northing: "",
				origin_latitude: "",
				origin_longitude: "",
				scale_factor: "",
				vertical_offset: "",
				incline_x: "",
				incline_y: "",
				to_meters: "",
				epsg: "",
				epsg_v: "",
				updated_at: "",
			};
			if (isSave) {
				uploadedData = {
					epsg:
						this.EPSG_code !== null
							? this.EPSG_code.coordinate_system
							: undefined,
					file: this.localFile,
					v_Shift:
						this.vShift !== null ? this.vShift.vertical_shift : undefined,
				};
			} else {
				this.$emit("close");
				return false;
			}
			await this.$store.dispatch("executeWithSpinner", async () => {
				// 編集中のユーザーがいれば処理中止
				const existEditingUser = await this.doesEditingUserExist();
				if (existEditingUser) return;
				if (this.tab === "tab1") {
					// 座標系から選択
					params.epsg = uploadedData.epsg;
					params.epsg_v = uploadedData.v_Shift;
					await this.updateCoordinateSystem(params);
				} else if (this.tab === "tab2") {
					params.rotation =
						saveData.localization?.projection.parameters?.rotation_angle;
					params.origin_easting =
						saveData.localization?.projection.parameters?.false_easting;
					params.origin_northing =
						saveData.localization?.projection.parameters?.false_northing;
					params.origin_latitude =
						saveData.localization?.projection.parameters?.latitude_of_origin;
					params.origin_longitude =
						saveData.localization?.projection.parameters?.central_meridian;
					params.scale_factor =
						saveData.localization?.projection.parameters?.scale_factor;
					params.vertical_offset =
						saveData.localization?.projection.parameters?.vertical_offset;
					params.incline_x =
						saveData.localization?.projection.parameters?.inclination_x;
					params.incline_y =
						saveData.localization?.projection.parameters?.inclination_y;
					params.to_meters =
						saveData.localization?.projection.parameters?.unit?.ratio;
					await this.request(params);
				}
			});
			if (this.isProjectPage) {
				this.$emit("close");
			} else {
				this.$emit("close", uploadedData);
			}
		},
		/**
		 * 編集中のユーザーが存在するかチェックする
		 * 存在すればTRUE、しなければFALSEを返す
		 */
		async doesEditingUserExist() {
			try {
				// ロック中のユーザーが存在するかチェック
				const result = await lockUtils.callGetLockInfoRequest(this.siteId);
				const lockedUsers = result.data;
				// 存在する場合メッセージを表示
				if (lockedUsers.length) {
					// 自分以外にロックしているユーザーがいるかどうかで表示メッセージを変える
					const isLockedByOthers = lockedUsers.find(
						(lock) => lock.locked_by !== this.$store.state.user.id,
					);
					if (isLockedByOthers) {
						this.set_snackbar({
							text: this.$t("UPDATE_FAILED_OTHER_USER_EDITING"),
							color: "rgba(153, 0, 0, 0.72)",
						});
					} else {
						this.set_snackbar({
							text: this.$t("UPDATE_FAILED_SELF_EDITING"),
							color: "rgba(153, 0, 0, 0.72)",
						});
					}
					return true;
				} else {
					return false;
				}
			} catch (e) {
				this.set_snackbar({
					text: this.$t("FAILED_TO_GET_EDITING_USER"),
					color: "rgba(153, 0, 0, 0.72)",
				});
				throw e;
			}
		},
		fileInp1Changed() {
			const txtContainer = document.querySelector(
				".fileInp1 .v-file-input__text",
			);
			if (txtContainer) {
				txtContainer.innerHTML = "";
			}
		},
		onTabSwitch() {
			this.showDashboardLocalizationData = false;
			this.dashboardInfo = {
				type: "",
				horizontal: "",
				vertical: "",
				obliqueStereographic: {},
			};
		},
		onSubmit() {
			this.firstDialog = false;
			this.confirmDialog = true;
		},
		async request(params) {
			const errorMessage = this.$t("CEW0020800001");
			try {
				// 呼び出し元のページを判定し現場IDを取得
				const res = await axios.post(
					`${import.meta.env.VITE_API_BASE}/sites/epsg/${this.siteId}`,
					params,
					{
						auth: this.$store.state.authObject,
					},
				);
				if (res.status === 200) {
					if (this.isProjectPage) {
						// プロジェクト画面の場合、現場情報を最新化する
						this.$store.dispatch("getSiteInfo", this.siteId);
					}
				}
			} catch (e) {
				const status = e.response?.status;
				console.error(e);
				if (status) {
					this.$store.commit("set_snackbar", {
						text: this.$t("UPDATE_FAILED_EDITING_OR_UPLOADING"),
						color: "rgba(153, 0, 0, 0.72)",
					});
				} else {
					this.$store.commit("set_snackbar", {
						text: errorMessage,
						color: "rgba(153, 0, 0, 0.72)",
					});
				}
			}
		},
		async updateCoordinateSystem(params) {
			try {
				// Update Site Coordinate System on PF
				const response = await axios.put(
					`${import.meta.env.VITE_API_BASE}/sites/${this.siteId}/coordinate-system`,
					{
						srid: params.epsg,
						vertical_srid: params.epsg_v,
					},
					{
						auth: this.$store.state.authObject,
					},
				);
				if (response.status === 200) {
					// Update DB
					await this.request(params);
				}
			} catch (e) {
				this.$store.commit("set_snackbar", {
					text: this.$t("CEW0020800001"),
					color: "rgba(153, 0, 0, 0.72)",
				});
			}
		},
		async getVerticalShift() {
			const res = await axios.get(
				`${import.meta.env.VITE_API_BASE}/geospatial/coordinateSystem`,
			);
			this.EPSG_code_items = res.data;
		},
		async getCoordinateSystem() {
			const res = await axios.get(
				`${import.meta.env.VITE_API_BASE}/geospatial/verticalShift`,
			);
			this.GEO_code_items = res.data;
		},
	},
	created() {
		this.getCoordinateSystem();
		this.getVerticalShift();
	},
};
</script>
<style scoped>
.content {
	margin-top: 5%;
	width: 90%;
	height: 100%;
}

.cnfDialog {
	width: 50%;
	margin-left: 30%;
}

.map {
	border: 1px solid;
	border-style: ridge;
	border-block-color: #c6ec1f;
	margin-bottom: 2%;
	margin-left: 4%;
	width: 100%;
}

div.v-tab {
	text-transform: none;
}

.dashboard-localization-message {
	white-space: pre-wrap;
	word-wrap: break-word;
}
</style>
