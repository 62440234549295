import { Drawing2dImage } from "../../models/Drawing2DImage";
import axios from "axios";

const state = {
	drawing2dImageList: [],
};

const getters = {
	getDrawing2dImageList: (state) => state.drawing2dImageList,
};

const mutations = {
	updateDrawing2dImageList: (state, drawing2dImageList) => {
		state.drawing2dImageList = drawing2dImageList;
	},
	deleteDrawing2dImageListStateById: (state, assetId) => {
		const drawing2dImageList = state.drawing2dImageList.filter(
			(drawing2dImage) => drawing2dImage.assetId !== assetId,
		);
		state.drawing2dImageList = drawing2dImageList;
	},
};

const actions = {
	async getDrawing2dImageList({ state, commit }, { siteId, tmpObj }) {
		// call backend api to get all drawing 2d image list
		// get drawing 2d image list from server but currently hard coded TODO:mr-akami
		const drawing2dImageList = [];
		const drawing2dImage = new Drawing2dImage(tmpObj);
		drawing2dImageList.push(drawing2dImage);
		commit("updateDrawing2dImageList", drawing2dImageList);
	},
	async deleteDrawing2dImage({ commit }, { siteId, drawing2dImage }) {
		try {
			await axios.delete(
				`${import.meta.env.VITE_API_BASE}/drawing2dImage/${siteId}/${drawing2dImage.assetId}`,
				{
					auth: state.authObject,
				},
			);
		} catch (e) {
			console.warn(e);
			throw e;
		}
		commit("deleteDrawing2dImageListStateById", drawing2dImage.assetId);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
