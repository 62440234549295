export default class PointCloud {
	constructor(
		pointCloudId,
		pointCloudName,
		assetId,
		transparency,
		processingStatus,
		processingError,
		created_at,
		updated_at,
		selected_date,
		selected_time,
	) {
		// APIから取得するデータ
		this.point_cloud_id = pointCloudId;
		this.point_cloud_name = pointCloudName;
		this.asset_id = assetId;
		this.transparency = transparency;
		this.processing_status = processingStatus;
		this.processing_error = processingError;
		this.created_at = new Date(created_at);
		this.updated_at = new Date(updated_at);
		this.selected_date = selected_date;
		this.selected_time = selected_time;

		// フロント側で設定するデータ
		// Cesiumへのアップロード状況
		this.is_asset_uploaded = "PENDING";
		// パネルの開閉状況
		this.expansion = false;
		// 点群の可視状況
		this.visibility = false;
		// タイリング状況（viewerに読み込み済みであればTrue）
		this.is_tiling = false;
		// タイリング進捗
		this.tiling_progress = false;
		// クリエーションモードの対象か否か
		this.is_creating = false;
		// アセットのバージョン
		this.version = 0;
	}

	// getter
	getId() {
		return this.point_cloud_id;
	}

	getName() {
		return this.point_cloud_name;
	}

	getAssetId() {
		return this.asset_id;
	}

	getAssetIdNum() {
		return Number.parseInt(this.asset_id);
	}

	getProcessingStatus() {
		return this.processing_status;
	}

	getProcessingError() {
		return this.processing_error;
	}

	getCreatedAt() {
		return this.created_at;
	}

	getUpdatedAt() {
		return this.updated_at;
	}

	getTransparency() {
		return this.transparency;
	}

	getIsAssetUploaded() {
		return this.is_asset_uploaded;
	}

	getExpansion() {
		return this.expansion;
	}

	getVisibility() {
		return this.visibility;
	}

	getIsTiling() {
		return this.is_tiling;
	}

	getTilingProgress() {
		return this.tiling_progress;
	}

	getIsCreating() {
		return this.is_creating;
	}

	getVersion() {
		return this.version;
	}

	getSelectedDate() {
		return this.selected_date;
	}
	getSelectedTime() {
		this.selected_time;
	}

	getTimeStamp() {
		const date = new Date(this.selected_date + "T" + this.selected_time);
		return this.selected_date && this.selected_time
			? [
					date.toLocaleString("en-US", { day: "2-digit" }),
					date.toLocaleString("en-US", { month: "short" }) + ",",
					date.toLocaleString("en-US", { year: "numeric" }),
					date.toLocaleString("en-US", { hour: "numeric", minute: "2-digit" }),
				].join(" ")
			: "";
	}

	// setter
	setId(pointCloudId) {
		this.point_cloud_id = pointCloudId;
	}

	setName(pointCloudName) {
		this.point_cloud_name = pointCloudName;
	}

	setAssetId(assetId) {
		this.asset_id = assetId;
	}

	setProcessingStatus(processingStatus) {
		this.processing_status = processingStatus;
	}

	setProcessingError(processingError) {
		this.processing_error = processingError;
	}

	setCreatedAt(created_at) {
		this.created_at = new Date(created_at);
	}

	setUpdatedAt(updated_at) {
		this.updated_at = new Date(updated_at);
	}

	setTransparency(transparency) {
		this.transparency = transparency;
	}

	setIsAssetUploaded(isAssetUploaded) {
		this.is_asset_uploaded = isAssetUploaded;
	}

	setExpansion(expansion) {
		this.expansion = expansion;
	}

	setVisibility(visibility) {
		this.visibility = visibility;
	}

	setIsTiling(tiling) {
		this.is_tiling = tiling;
	}

	setTilingProgress(tilingProgress) {
		this.tiling_progress = tilingProgress;
	}

	setIsCreating(isCreating) {
		this.is_creating = isCreating;
	}

	setVersion(version) {
		this.version = version;
	}

	setSelectedDate(date) {
		this.selected_date = date;
	}
	setSelectedTime(time) {
		this.selected_time = time;
	}

	// functions
	/**
	 * アセットのアップロードが完了するかエラーになるまで待つ
	 * 完了の場合DONEを返し、エラーの場合ERRORを返す
	 * @returns Promise
	 */
	waitAssetUploading() {
		const checkAssetUploaded = (resolve, reject, timeId) => {
			let isResolveOrReject = false;
			if (this.getIsAssetUploaded() === "DONE") {
				resolve(this.getIsAssetUploaded());
				if (timeId) clearInterval(timeId);
				isResolveOrReject = true;
			} else if (this.getIsAssetUploaded() === "ERROR") {
				reject(this.getIsAssetUploaded());
				if (timeId) clearInterval(timeId);
				isResolveOrReject = true;
			}
			return isResolveOrReject;
		};

		return new Promise((resolve, reject) => {
			// 1回目のチェック
			if (checkAssetUploaded(resolve, reject)) return;

			// 2回目以降のチェック
			const timeId = setInterval(() => {
				if (checkAssetUploaded(resolve, reject, timeId)) return;
			}, 1000);
		});
	}

	update(data) {
		this.setName(data.point_cloud_name);
		// アセットidに変更がある場合
		if (this.getAssetId() !== data.asset_id) {
			if (this.getIsTiling()) {
				// アセットを読み込み済みであればリムーブする
				window["viewer"].removeTileset(this.getAssetId(), "pointcloud");
			}
			// ステータスを初期化する
			this.initialize();
		}
		this.setAssetId(data.asset_id);
		this.setTransparency(data.transparency);
		this.setProcessingStatus(data.processing_status);
		this.setProcessingError(data.processing_error);
		this.setCreatedAt(data.create_at);
		this.setUpdatedAt(data.updated_at);
		this.setSelectedDate(data.selected_date);
		this.setSelectedTime(data.selected_time);
	}

	initialize() {
		this.setIsAssetUploaded("PENDING");
		this.setExpansion(false);
		this.setVisibility(false);
		this.setIsTiling(false);
		this.setTilingProgress(false);
		this.setIsCreating(false);
	}
}
