<template>
  <v-fade-transition>
      <v-progress-circular
        v-if="showUploadProgress"
        class="normal-circle flex-shrink-0"
        :rotate="-90"
        :size="20"
        :width="4"
        :model-value="percentComplete"
        color="primary"
      >
      </v-progress-circular>
      <v-progress-circular
        v-else-if="isUploadError"
        class="error-circle flex-shrink-0"
        :rotate="-90"
        :size="20"
        :width="4"
        :model-value="percentComplete"
      >
      </v-progress-circular>
  </v-fade-transition>
</template>

<script>
import axios from "axios";
import { getCookie } from "../../utils/getCookie";

export default {
	props: {
		entity: Object,
		showUploadProgress: Boolean,
		isUploadError: Boolean,
	},
	data() {
		return {
			percentComplete: 0,
		};
	},
	watch: {
		// アセットIDに変更があれば再度ポーリング開始
		showUploadProgress(newValue) {
			if (newValue) {
				this.resetProgress();
				this.startPolling();
			}
		},
	},
	methods: {
		resetProgress() {
			this.percentComplete = 0;
		},
		updateIsAssetUploaded(isAssetUploaded) {
			this.entity.setIsAssetUploaded(isAssetUploaded);
		},
		updateAssetVersion(version) {
			this.entity.setVersion(version);
		},
		// 進捗率が100になるまで10秒ごとにアップロード状況をチェックする
		// エラーが発生した場合は停止する
		async startPolling() {
			await this.checkUploadStatus(this.entity.getAssetId());
			const timeId = setInterval(() => {
				if (this.isUploadError || this.percentComplete === 100) {
					clearInterval(timeId);
					return;
				}
				this.checkUploadStatus(this.entity.getAssetId());
			}, 10000);
		},
		// アセットのアップロード状況をチェックし、プログレスサークルの状態を更新する
		async checkUploadStatus(assetId) {
			try {
				const result = await this.polling(assetId);
				// アップロード結果がエラーの場合はエラーサークルを表示する
				if (result.isError) {
					throw new Error("Asset upload result is error.");
				} else {
					this.percentComplete = result.percentComplete;
					// 進捗率が100%であれば2秒後にプログレスサークルを閉じる
					if (result.percentComplete === 100) {
						this.updateIsAssetUploaded("DONE");
						setTimeout(() => {
							this.updateUploadProgress(false);
						}, 2000);
						if (assetId >= Number(import.meta.env.VITE_TILE_ASSET_ID)) {
							this.updateAssetVersion(result.version);
						}
					}
				}
			} catch (error) {
				console.error(error.message);
				this.updateUploadProgress(false);
				this.updateUploadErrorStatus(true);
				this.updateIsAssetUploaded("ERROR");
			}
		},
		// 問い合わせ成功時は進捗率（0～100）を返却し、失敗時は0を返却する
		async polling(assetId) {
			if (assetId >= Number(import.meta.env.VITE_TILE_ASSET_ID)) {
				let isError = false;
				let percentComplete = 0;
				let version = 0;
				const token = getCookie("access_token");
				try {
					// 問い合わせ実施
					const res = await axios.get(
						`${import.meta.env.VITE_API_TILE_ASSET}/v1/asset/${assetId}/job`,
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
							withCredentials: false,
						},
					);
					// 問い合わせ結果
					// ステータスがエラーもしくは進捗率が設定されていない場合
					if (res.data.status === "Failed") {
						console.log("status_failed");
						isError = true;
					} else {
						// 進捗率を更新
						percentComplete = res.data[0].progress;
						version = res.data[0].version;
					}
				} catch (e) {
					console.error(e.message);
					isError = true;
				}
				return { isError, percentComplete, version };
			} else {
				const token =
					"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNDBhZDk4OS0xMjkzLTQ5YjEtOTUzMi03MDQ1N2E1ZWEyMDAiLCJpZCI6MjkwNTIsImlhdCI6MTY0NjAxNTYzOH0.Gj_ao_F4uAhdt2kORDyJxwoOC_tckqMqCQYKiy4VpV8";
				let isError = false;
				let percentComplete = 0;
				try {
					// 問い合わせ実施
					const res = await axios.get(
						`https://api.cesium.com/v1/assets/${assetId}`,
						{
							headers: { Authorization: `Bearer ${token}` },
							withCredentials: false, // trueにするとcorsでエラーになるため明示的にfalseにする
						},
					);

					// 問い合わせ結果
					const assetMetadata = res.data;

					// ステータスがエラーもしくは進捗率が設定されていない場合
					if (
						assetMetadata.status === "ERROR" ||
						assetMetadata.status === "DATA_ERROR"
					) {
						isError = true;
					} else {
						// 進捗率を更新
						percentComplete = assetMetadata.percentComplete;
					}
				} catch (e) {
					console.error(e.message);
					isError = true;
				}
				return { isError, percentComplete };
			}
		},
		/**
		 * アップロード進捗を表示するフラグを更新する
		 */
		updateUploadProgress(value) {
			this.$emit("update-upload-progress", value);
		},
		/**
		 * アップロードエラーの有無を判定するフラグを更新する
		 */
		updateUploadErrorStatus(value) {
			this.$emit("update-upload-error-status", value);
		},
	},
	async created() {
		this.startPolling();
	},
};
</script>

<style lang="sass" scoped>
.normal-circle :deep(svg circle.v-progress-circular__underlay)
  stroke: black

.error-circle :deep(svg circle.v-progress-circular__underlay)
  stroke: #F44336
</style>