import { createRouter, createWebHistory } from "vue-router";
import ListPage from "../pages/ListPage";
import ProjectPage from "../pages/ProjectPage";
import versionUpdate from "../utils/versionUpdate";
import store from "../store";
import { i18n } from "@/i18n.js";
import { getCookie } from "../utils/getCookie";

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			name: "top",
			path: "/top",
			component: ListPage,
		},
		{
			name: "project",
			path: "/",
			component: ProjectPage,
			beforeEnter: async (to, _from, next) => {
				try {
					const siteId = to.query.siteId;
					await store.dispatch("getSiteInfo", siteId);
					next();
				} catch (error) {
					// 現場取得エラーの場合トップ画面にリダイレクトしエラーメッセージを表示する
					let errorMessage;
					let color = undefined;
					if (error.response?.status === 403) {
						errorMessage = i18n.global.t("NO_PERMISSION_TO_VIEW_SITE");
					} else if (error.response?.status === 404) {
						errorMessage = i18n.global.t("NOT_EXIST_SITE_INFO");
						color = "rgba(245, 124, 0, 0.72)";
					} else {
						errorMessage = i18n.global.t("FAILED_TO_FETCH_SITE_INFO");
					}
					store.commit("set_snackbar", {
						text: errorMessage,
						color: color ?? "rgba(153, 0, 0, 0.72)",
					});
					next("/top");
				}
			},
		},
	],
});

router.beforeEach(async (to, _from, next) => {
	const login_auth = getCookie("login_auth");

	// ブラウザ更新また別タブで立ち上げたときRefresh機能適用
	if (!login_auth || !window.name) {
		document.cookie = `requested_path=${to.fullPath}`; // ログイン後に遷移するパスを保存
		window.name = "init";
		window.location.href = `${import.meta.env.VITE_API_BASE}/login`;
	} else {
		//ソースがサーバーと一致するかどうかを判断し、一致しなければ画面をリフレッシュして最新版を取得します
		versionUpdate.isNewVersion();
		const requestedPath = getCookie("requested_path");
		if (requestedPath) {
			document.cookie = "requested_path=; max-age=0";
			next(requestedPath);
		} else if (to.path === "/" && !to.query.siteId) {
			next("/top");
		} else {
			next();
		}
	}
});

/**
 * TOP画面初期状態
 */
router.beforeEach((to, _from, next) => {
	const scUserId = getCookie("login_auth");
	const scCorporationId = getCookie("sc_corporation");

	if (to.path === "/top") {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			sc_corporation_id: scCorporationId,
			sc_user_id: scUserId,
		});
	}
	next();
});

/**
 * TOPのルーティングが完了した後に実行
 */
router.afterEach((to) => {
	if (to.path === "/top") {
		window.dataLayer.push({
			event: "sc_open",
		});
	}
});

export default router;
