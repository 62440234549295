import * as constants from "@/constant.js";
import { i18n } from "@/i18n.js";
import Design from "@/models/Design";
import Line from "@/models/Line";
import PointCloud from "@/models/PointCloud";
import store from "@/store/index";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getCookie } from "./getCookie";
import localCacheDB from "./local-db.js";
import { convertPointsToPointCloudCRS } from "./wsConvertToPointCloudCRS";
import { convertPointsToWGS84CRS } from "./wsConvertToWGS84";

const worker = new Worker(
	new URL("./DataConversionWorker.js", import.meta.url),
	{ type: "module" },
);

function debounce(func, timeout = 300) {
	let timer;
	return (...args) => {
		return new Promise((resolve, reject) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				try {
					const result = func.apply(this, args);
					if (result instanceof Promise) {
						result.then(resolve).catch(reject);
					} else {
						resolve(result);
					}
				} catch (error) {
					reject(error);
				}
			}, timeout);
		});
	};
}

export const startWorker = () => {
	const wsServerUrl =
		import.meta.env.VITE_WEB_SOCKET_URL + "/wsPointCloudTransformer";
	const data = { action: "INIT", payload: wsServerUrl };
	worker.postMessage(JSON.stringify({ data }));
};

export const uploadRoadObj = debounce((props) => {
	const objConnectionJSON = window["viewer"].getConnectionsInSiteJSON();
	const json = window["viewer"].getRoadJSON(props.id);
	props.json = json;
	return store.dispatch("updateObj", { json, props, objConnectionJSON });
}, 300);

export const uploadTrenchObj = debounce((props) => {
	const json = window["viewer"].getTrenchJSON(props.id);
	props.json = json;
	return store.dispatch("updateObj", { json, props });
}, 300);

export const uploadFlatGroundObj = debounce((props) => {
	const objConnectionJSON = window["viewer"].getConnectionsInSiteJSON();
	const json = window["viewer"].getFlatGroundJSON(props.id);
	props.json = json;
	return store.dispatch("updateObj", { json, props, objConnectionJSON });
}, 300);

/**
 * オブジェクトの更新処理に共通のエラーメッセージ出力を追加したラッパーメソッド
 * @param {*} props
 * @param {*} objType 処理対象のオブジェクトのタイプ
 */
export const uploadObjAndNotify = async (props, objType) => {
	try {
		if (objType === constants.objectType.ROAD) {
			await uploadRoadObj(props);
		} else if (objType === constants.objectType.FLAT) {
			await uploadFlatGroundObj(props);
		} else if (objType === constants.objectType.TRENCH) {
			await uploadTrenchObj(props);
		}
	} catch (e) {
		const status = e.response?.status;
		if (status === 409) {
			store.commit("set_snackbar", {
				text: i18n.global.t("EDITING_BY_OTHER_USER"),
				color: "rgba(153, 0, 0, 0.72)",
			});
		} else {
			store.commit("set_snackbar", {
				text: `${i18n.global.t("UPDATE_DATA")} ${i18n.global.t("failed")}`,
				color: "rgba(153, 0, 0, 0.72)",
			});
		}
	} finally {
		if (store.state.isObjectUpdating) {
			store.dispatch("endObjectUpdating");
		}
	}
};

/**
 * 基準地形の可視不可視を変更する
 * @param {*} pointCloud
 */
export const togglePointCloudVisibility = async (pointCloud, roadList) => {
	if (!pointCloud.getVisibility()) {
		// 不可視から可視への切り替え
		if (pointCloud.getIsTiling()) {
			// タイリング済みの場合、新たにタイリング処理を行わない
			pointCloud.setVisibility(true);
			window["viewer"].updateTilesetVisibility(
				Number.parseInt(pointCloud.getAssetId()),
				"pointcloud",
				pointCloud.getVisibility(),
			);
			// 表示した設計データにフォーカスする
			zoomToTileset(pointCloud);
			await localCacheDB.addPointCloud(
				store.state.user.id,
				store.state.site.site_uuid,
				pointCloud.getAssetId(),
			);
		} else {
			// スピナーを表示する
			store.dispatch("updateIsLoading", true);
			// タイリング中のステータスをtrueにする
			pointCloud.setTilingProgress(true);

			// タイリングのためのデータを取得し、タイリング処理を実行する
			try {
				await addPointCloudTileset(pointCloud);

				// After pointclod is loaded, calculate geometry for roads which were loaded before
				const findAssociateRoadsWithoutGeom = roadList.filter((road) => {
					return (
						road.json &&
						JSON.parse(road.json).associatePointCloudId &&
						JSON.parse(road.json).associatePointCloudId ===
							Number.parseInt(pointCloud.asset_id) &&
						road.isLoadedWithoutPointCloud
					);
				});
				findAssociateRoadsWithoutGeom.forEach((road) => {
					window["viewer"].recalculateRoadGeometry(road.id);
					road.isLoadedWithoutPointCloud = false;
				});

				// ステータスを可視状態かつタイリング済みにする
				pointCloud.setVisibility(true);
				pointCloud.setIsTiling(true);

				// 表示点群リストに追加
				store.dispatch("addToVisiblePointCloudAndDesignList", pointCloud);

				// タイリング中のステータスをfalseに戻す
				pointCloud.setTilingProgress(false);

				// スピナーを非表示にする
				store.dispatch("updateIsLoading", false);
				await localCacheDB.addPointCloud(
					store.state.user.id,
					store.state.site.site_uuid,
					pointCloud.getAssetId(),
				);
			} catch (err) {
				console.error(err.message);
				// タイリング中のステータスをfalseに戻す
				pointCloud.setTilingProgress(false);
				// スピナーを非表示にする
				store.dispatch("updateIsLoading", false);
				await localCacheDB.deletePointCloud(
					store.state.user.id,
					store.state.site.site_uuid,
					pointCloud.getAssetId(),
				);

				if (err.message === "conversion error") {
					store.commit("set_snackbar", {
						text: `座標系が異なるため、タイリング中で計算で失敗`,
						color: "rgba(153, 0, 0, 0.72)",
					});
				}
				throw err;
			}
		}
	} else {
		// 可視から不可視への切り替え
		pointCloud.setVisibility(false);
		await localCacheDB.deletePointCloud(
			store.state.user.id,
			store.state.site.site_uuid,
			pointCloud.getAssetId(),
		);
		window["viewer"].updateTilesetVisibility(
			Number.parseInt(pointCloud.getAssetId()),
			"pointcloud",
			pointCloud.getVisibility(),
		);
		// 表示点群リストから削除
		store.dispatch("removeFromVisiblePointCloudAndDesignList", pointCloud);
		// 点群に表示されている線形、距離、断面を削除する
		store.getters["measurement/measurementList"]
			.filter((item) => item.assetId == pointCloud.getAssetId())
			.forEach((item) => {
				deleteMeasurement(item);
			});
	}
};

/**
 * 対象点群にフォーカスする
 * @param {*} target PointCloud or Design
 */
export const zoomToTileset = (target) => {
	if (target instanceof PointCloud) {
		window["viewer"].zoomToTileset(target.getAssetId(), "pointcloud");
	} else if (target instanceof Design) {
		window["viewer"].zoomToTileset(target.getAssetId(), "design");
	} else if (target instanceof Line) {
		if (target.getLineType() === "dxf") {
			window["viewer"].zoomToDXF(target.getId());
		} else if (target.getLineType() === "sima") {
			window["viewer"].zoomToSIMA(target.getId());
		}
	}
	// 表示点群リストの先頭に移動
	store.dispatch("addToVisiblePointCloudAndDesignList", target);
};

export const zoomToDrawing2dImage = (target) => {
	store.getters.viewer.zoomToDrawing2dImage(target.getAssetId());
};

export const removeDrawing2dImage = (drawing2dImage) => {
	store.getters.viewer.removeDrawing2dImage(drawing2dImage.assetId);
};

/**
 * 設計データの可視不可視を変更する
 * @param {*} design
 */
export const toggleDesignVisibility = async (design) => {
	if (!design.getVisibility()) {
		// 不可視から可視への切り替え
		if (design.getIsTiling()) {
			// タイリング済みの場合、新たにタイリング処理を行わない
			design.setVisibility(true);
			window["viewer"].updateTilesetVisibility(
				Number.parseInt(design.getAssetId()),
				"design",
				design.getVisibility(),
			);
			// 表示した設計データにフォーカスする
			zoomToTileset(design);
			await localCacheDB.addDesign(
				store.state.user.id,
				store.state.site.site_uuid,
				design.getAssetId(),
			);
		} else {
			// スピナーを表示する
			store.dispatch("updateIsLoading", true);
			// タイリング中のステータスをtrueにする
			design.setTilingProgress(true);
			try {
				// タイリングのためのデータを取得し、タイリング処理を実行する
				await addDesignDataTileset(design);
				// ステータスを可視状態かつタイリング済みにする
				design.setVisibility(true);
				design.setIsTiling(true);
				await localCacheDB.addDesign(
					store.state.user.id,
					store.state.site.site_uuid,
					design.getAssetId(),
				);

				// 表示点群リストに追加
				store.dispatch("addToVisiblePointCloudAndDesignList", design);
			} catch (e) {
				console.error(e.message);
				await localCacheDB.deleteDesign(
					store.state.user.id,
					store.state.site.site_uuid,
					design.getAssetId(),
				);

				if (e.message === "conversion error") {
					store.commit("set_snackbar", {
						text: `座標系が異なるため、タイリング中で計算で失敗`,
						color: "rgba(153, 0, 0, 0.72)",
					});
				} else {
					store.commit("set_snackbar", {
						text: `タイリングに失敗しました`,
						color: "rgba(153, 0, 0, 0.72)",
					});
				}
				throw e;
			} finally {
				// タイリング中のステータスをfalseに戻す
				design.setTilingProgress(false);
				// スピナーを非表示にする
				store.dispatch("updateIsLoading", false);
			}
		}
	} else {
		// 可視から不可視への切り替え
		design.setVisibility(false);
		await localCacheDB.deleteDesign(
			store.state.user.id,
			store.state.site.site_uuid,
			design.getAssetId(),
		);

		window["viewer"].updateTilesetVisibility(
			Number.parseInt(design.getAssetId()),
			"design",
			design.getVisibility(),
		);
		// 表示点群リストから削除
		store.dispatch("removeFromVisiblePointCloudAndDesignList", design);
	}
};

export const toggleDrawing2dImageVisibility = async (drawing2dImage) => {
	if (!drawing2dImage.getIsVisible()) {
		// 不可視から可視への切り替え
		drawing2dImage.setIsVisible(true);
		store.getters.viewer.updateDrawing2dImageVisibility(
			drawing2dImage.assetId,
			true,
		);
		// 表示した設計データにフォーカスする
		zoomToDrawing2dImage(drawing2dImage);
		// store.dispatch("addToVisiblePointCloudAndDesignList", drawing2dImage);
	} else {
		// 可視から不可視への切り替え
		drawing2dImage.setIsVisible(false);
		store.getters.viewer.updateDrawing2dImageVisibility(
			drawing2dImage.assetId,
			false,
		);
	}
};

export const updateDrawing2dImageTransparency = async (drawing2dImage) => {
	store.getters.viewer.updateDrawing2dImageTransparency(
		Number.parseInt(drawing2dImage.assetId),
		drawing2dImage.transparency,
	);
};

export const addDrawing2dImage = async (drawing2dImage) => {
	const convertToBaseInfo = (image) => {
		return {
			file: image.file,
			name: image.file.name,
			assetId: image.assetId,
			centerLatitude: Number(image.latitude),
			centerLongitude: Number(image.longitude),
			cornerPositions: image.cornerPositions,
			rotateDegree: image.rotateDegree,
			imageWidth: image.imageWidth,
			imageHeight: image.imageHeight,
			isDraped: image.isDraped,
			isVisible: image.isVisible,
			transparency: image.transparency,
		};
	};

	if (Array.isArray(drawing2dImage)) {
		const promises = drawing2dImage.map(async (image) => {
			const drawing2dImageBaseInfo = convertToBaseInfo(image);
			return store.getters.viewer.addDrawing2dImage(drawing2dImageBaseInfo);
		});
		await Promise.all(promises);
	} else {
		const drawing2dImageBaseInfo = convertToBaseInfo(drawing2dImage);
		await store.getters.viewer.addDrawing2dImage(drawing2dImageBaseInfo);
	}
};

/**
 * 仮設道路の可視不可視を変更する
 * @param {*} trenchEntity
 */
export const setTrenchVisibility = async (
	trenchEntity,
	isLoadedWithoutPointCloud,
) => {
	if (trenchEntity.isShow && !trenchEntity.isLoaded) {
		await store.dispatch("executeWithSpinner", async () => {
			try {
				// TODO Remove window["viewer"]
				trenchEntity.id = await window["viewer"].loadTrenchJSON(
					trenchEntity.json,
				);
				const lengthOfTrench = window["viewer"].getLengthOfTrench(
					trenchEntity.id,
				);
				if (lengthOfTrench) {
					trenchEntity.info.length = Number.parseFloat(
						lengthOfTrench.toFixed(3),
					);
				}
				trenchEntity.info.height = window["viewer"].getTrenchHeightInfo(
					trenchEntity.id,
				);
				trenchEntity.info.slope = window["viewer"].getTrenchSlopeInfo(
					trenchEntity.id,
				);
				trenchEntity.isLoaded = true;
				trenchEntity.isLoadedWithoutPointCloud = isLoadedWithoutPointCloud;
			} catch (e) {
				console.error(e);
				trenchEntity.isShow = false;
				trenchEntity.isLoaded = false;
			}
		});
	} else {
		// TODO Remove window["viewer"]
		window["viewer"].setTrenchVisibility(trenchEntity.id, trenchEntity.isShow);
	}
};

/**
 * road Visibility
 * @param {*} roadEntity
 */
export const toggleRoadVisibility = async (
	roadEntity,
	associatedPointCloudId,
	isLoadedWithoutPointCloud,
) => {
	if (roadEntity.isShow && !roadEntity.isLoaded) {
		await store.dispatch("executeWithSpinner", async () => {
			try {
				roadEntity.id = await window["viewer"].loadRoadJSON(
					roadEntity.json,
					associatedPointCloudId,
				);
				const lengthOfRoad = window["viewer"].getLengthOfRoad(roadEntity.id);
				if (lengthOfRoad) {
					roadEntity.info.length = Number.parseFloat(lengthOfRoad.toFixed(3));
				}
				roadEntity.info.height = window["viewer"].getRoadHeightInfo(
					roadEntity.id,
				);
				roadEntity.info.slope = window["viewer"].getRoadSlopeInfo(
					roadEntity.id,
				);
				roadEntity.isLoaded = true;
				roadEntity.isLoadedWithoutPointCloud = isLoadedWithoutPointCloud;
			} catch (e) {
				console.error(e);
				roadEntity.isShow = false;
				roadEntity.isLoaded = false;
			}
		});
	} else {
		// TODO Remove window["viewer"]
		window["viewer"].toggleRoadVisibility(roadEntity.id, roadEntity.isShow);
	}
};

/**
 * 平場の可視不可視を変更する
 * @param {*} flatEntity
 */
export const toggleFlatGroundVisibility = async (
	flatEntity,
	activePointCloudId,
) => {
	if (flatEntity.isShow && !flatEntity.isLoaded) {
		await store.dispatch("executeWithSpinner", async () => {
			try {
				flatEntity.id = await window["viewer"].loadFlatGroundArea(
					flatEntity.json,
					activePointCloudId,
				);
				const maxHeight = window["viewer"].getMaxHeightOfFlatGroundCtrlPts(
					flatEntity.id,
				);
				flatEntity.info.maxHeight = maxHeight.toFixed(3);
				flatEntity.isLoaded = true;
			} catch (e) {
				console.error(e);
				flatEntity.isShow = false;
				flatEntity.isLoaded = false;
			}
		});
	} else {
		window["viewer"].toggleFlatGroundVisibility(
			flatEntity.id,
			flatEntity.isShow,
		);
	}
};

export const hexToRgb = (hex) => {
	let c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 1 };
	}
	throw new Error("Bad Hex");
};

export const convertCoordinateToCesiumCrs = async ({ x, y, z }) => {
	try {
		const result = await convertCoordinatesToCesiumCrs([{ x, y, z }]);
		return result.length > 0
			? result[0]
			: { x: Number.NaN, y: Number.NaN, z: Number.NaN };
	} catch (e) {
		return { x: Number.NaN, y: Number.NaN, z: Number.NaN };
	}
};

//hack to make worker synchronous
const mapOfPromises = new Map();
worker.onmessage = (e) => {
	const result = JSON.parse(e.data);
	const id = result.id;
	const resolve = mapOfPromises.get(id);
	console.log(
		"Received Local Coordinates of length",
		result.wgs84Coords.length,
	);
	resolve && resolve(result.wgs84Coords);
	mapOfPromises.delete(id);
};

export const convertCoordinatesToCesiumCrs = async (coords) => {
	console.log("Sending Local Coordinates of length", coords.length);
	const data = {
		action: "TRANSFORM",
		payload: { coords, site: store.state.site },
	};
	return new Promise((resolve) => {
		const id = uuidv4();
		worker.postMessage(JSON.stringify({ id, data }));
		mapOfPromises.set(id, resolve);
	});
};

export const convertCoordinateToPointCloudCrs = async ({ x, y, z }) => {
	try {
		const result = await convertPointsToPointCloudCRS([{ x, y, z }]);
		return result.length > 0
			? result[0]
			: { x: Number.NaN, y: Number.NaN, z: Number.NaN };
	} catch (e) {
		return { x: Number.NaN, y: Number.NaN, z: Number.NaN };
	}
};

export const convertCoordinatesToPointCloudCrs = async (coords) => {
	let result = [];
	try {
		result = (await convertPointsToPointCloudCRS(coords)) || [];
	} catch (e) {
		result = [];
	}
	return result;
};

export const addPointCloudTileset = async (point_cloud) => {
	const heightmapResponse = await axios.get(
		`${import.meta.env.VITE_API_BASE}/pointcloud/heightmap/${point_cloud.point_cloud_id}`,
		{
			auth: store.state.authObject,
		},
	);
	let geometryBuffer;
	if (!heightmapResponse?.data?.hasData) {
		console.log("Height Map not found");
		console.log("Start to fetch Geometry");
		const geometryResponse = await axios.get(
			`${import.meta.env.VITE_API_BASE}/pointcloud/data/${point_cloud.point_cloud_id}`,
			{
				auth: store.state.authObject,
			},
		);
		console.log("Geometry Fetched");

		//geometry response is in wgs84
		if (geometryResponse?.data && geometryResponse?.data.length > 0) {
			console.log("Converting points to the Site coordinate", {
				x: geometryResponse.data[0],
				y: geometryResponse.data[1],
				z: geometryResponse.data[2],
			});

			const localPositions = [];
			// Rather than converting points one at a time or all at once, it is better to process in batch
			// Here batch of size 1000 points has been choosen
			const batchLength = 1000;

			// geometryResponse.data.length is a buffer/array of numbers organized in [x,y,z,x,y,z ....] fashion.
			// batchlength is multiplied by 3 to make a batch of 1000 points (point = {x,y,z})
			for (let i = 0; i < geometryResponse.data.length; i += batchLength * 3) {
				const endindex =
					geometryResponse.data.length - i >= batchLength * 3
						? i + batchLength * 3
						: geometryResponse.data.length;
				const wgs84positions = [];
				for (let j = i; j < endindex; j += 3) {
					wgs84positions.push({
						x: geometryResponse.data[j],
						y: geometryResponse.data[j + 1],
						z: geometryResponse.data[j + 2],
					});
				}
				const positions =
					await convertCoordinatesToPointCloudCrs(wgs84positions);
				localPositions.push(...positions);
				console.log(
					`Converting points to the Site coordinate: ${(endindex / geometryResponse.data.length) * 100}% completed`,
				);
			}
			if (localPositions.length === 0) throw new Error("geometry is empty");
			geometryBuffer = localPositions.flatMap((p) => [p.x, p.y, p.z]);
		}
	}
	if (!heightmapResponse?.data?.hasData && !geometryBuffer) {
		throw new Error(
			"There is neither heightmap nor triangulated geometry. Please upload the pointcloud again",
		);
	}
	return window["viewer"].addTileset(
		point_cloud.asset_id,
		"pointcloud",
		{
			heightMapInput: heightmapResponse.data.hasData
				? heightmapResponse.data.height_map
				: undefined,
			vertexBuffer: geometryBuffer,
			styleOptions: {
				common: { transparency: point_cloud.getTransparency() },
			},
		},
		point_cloud.asset_id >= Number(import.meta.env.VITE_TILE_ASSET_ID)
			? {
					accessToken: getCookie("access_token"),
					version: point_cloud.getVersion(),
					tileAssetUrl: import.meta.env.VITE_API_TILE_ASSET,
				}
			: null,
	);
};

export const addDesignDataTileset = async (design) => {
	const response = await axios.get(
		`${import.meta.env.VITE_API_BASE}/design/data/${design.design_id}`,
		{
			auth: store.state.authObject,
		},
	);
	const wgs84Points = [];
	for (let i = 0; i < response.data.vertices.length; i += 3) {
		wgs84Points.push({
			x: response.data.vertices[i],
			y: response.data.vertices[i + 1],
			z: response.data.vertices[i + 2],
		});
	}
	const localPoints = await convertCoordinatesToPointCloudCrs(wgs84Points);
	response.data.vertices = localPoints.flatMap((w) => [w.x, w.y, w.z]);
	return window["viewer"].addTileset(
		design.asset_id,
		"design",
		{
			vertexBuffer: response.data.vertices,
			indexBuffer: response.data.indices,
			styleOptions: {
				design: { mesh_color: hexToRgb(design.getMeshColor()) },
				common: { transparency: design.getTransparency() },
			},
		},
		design.asset_id >= Number(import.meta.env.VITE_TILE_ASSET_ID)
			? {
					accessToken: getCookie("access_token"),
					version: design.getVersion(),
					tileAssetUrl: import.meta.env.VITE_API_TILE_ASSET,
				}
			: null,
	);
};

export const updateRoadCommonSettings = (roadId, roadObj) => {
	const isNum = (val) => {
		return typeof val == "number";
	};
	const roadDataJSONStr = roadObj.json
		? roadObj.json
		: window["viewer"].getRoadJSON(roadId);
	const roadData = JSON.parse(roadDataJSONStr);

	const roadCommonSettings = roadData.roadSettings.commonSetting;
	//common settings
	roadCommonSettings.path.leftWidth = isNum(
		roadObj.settings.commonSettings.leftWidth,
	)
		? roadObj.settings.commonSettings.leftWidth
		: 2.5;
	roadCommonSettings.path.rightWidth = isNum(
		roadObj.settings.commonSettings.rightWidth,
	)
		? roadObj.settings.commonSettings.rightWidth
		: 2.5;
	roadCommonSettings.path.leftSlope = isNum(
		roadObj.settings.commonSettings.leftSlope,
	)
		? roadObj.settings.commonSettings.leftSlope
		: 50;
	roadObj.settings.commonSettings.rightSlope = isNum(
		roadObj.settings.commonSettings.leftSlope,
	)
		? roadObj.settings.commonSettings.leftSlope
		: 50;
	roadCommonSettings.path.rightSlope = roadObj.settings.commonSettings
		.isSuperElevation
		? -1 * roadObj.settings.commonSettings.rightSlope
		: roadObj.settings.commonSettings.rightSlope;
	roadCommonSettings.path.roadSlopeType = roadObj.settings.commonSettings
		.isSuperElevation
		? "straight"
		: "cross";
	roadCommonSettings.path.slope = isNum(
		roadObj.settings.commonSettings.leftSlope,
	)
		? roadObj.settings.commonSettings.leftSlope
		: 5;
	roadCommonSettings.path.interpolationPitch =
		isNum(roadObj.settings.commonSettings.interpolationPitch) &&
		roadObj.settings.commonSettings.interpolationPitch != 0
			? roadObj.settings.commonSettings.interpolationPitch
			: 5;
	if (roadObj.settings.cutSettings.bLeftRightSettings) {
		//separate left and right settings
		roadCommonSettings.cutSetting.left.slopeHeight = isNum(
			roadObj.settings.cutSettings.leftSlopeHeight,
		)
			? roadObj.settings.cutSettings.leftSlopeHeight
			: 5;
		roadCommonSettings.cutSetting.left.moundSlope = isNum(
			roadObj.settings.cutSettings.leftMoundSlope,
		)
			? roadObj.settings.cutSettings.leftMoundSlope
			: 1;
		roadCommonSettings.cutSetting.left.shelfWidth = isNum(
			roadObj.settings.cutSettings.leftShelfWidth,
		)
			? roadObj.settings.cutSettings.leftShelfWidth
			: 1.5;
		roadCommonSettings.cutSetting.left.shelfSlope = isNum(
			roadObj.settings.cutSettings.leftShelfSlope,
		)
			? roadObj.settings.cutSettings.leftShelfSlope
			: 100;

		roadCommonSettings.cutSetting.right.slopeHeight = isNum(
			roadObj.settings.cutSettings.rightSlopeHeight,
		)
			? roadObj.settings.cutSettings.rightSlopeHeight
			: 5;
		roadCommonSettings.cutSetting.right.moundSlope = isNum(
			roadObj.settings.cutSettings.rightMoundSlope,
		)
			? roadObj.settings.cutSettings.rightMoundSlope
			: 1;
		roadCommonSettings.cutSetting.right.shelfWidth = isNum(
			roadObj.settings.cutSettings.rightShelfWidth,
		)
			? roadObj.settings.cutSettings.rightShelfWidth
			: 1.5;
		roadCommonSettings.cutSetting.right.shelfSlope = isNum(
			roadObj.settings.cutSettings.rightShelfSlope,
		)
			? roadObj.settings.cutSettings.rightShelfSlope
			: 100;
	} else {
		roadCommonSettings.cutSetting.left.slopeHeight = isNum(
			roadObj.settings.cutSettings.roadHeight,
		)
			? roadObj.settings.cutSettings.roadHeight
			: 5;
		roadCommonSettings.cutSetting.left.moundSlope = isNum(
			roadObj.settings.cutSettings.moundSlope,
		)
			? roadObj.settings.cutSettings.moundSlope
			: 1;
		roadCommonSettings.cutSetting.left.shelfWidth = isNum(
			roadObj.settings.cutSettings.shelfWidth,
		)
			? roadObj.settings.cutSettings.shelfWidth
			: 1.5;
		roadCommonSettings.cutSetting.left.shelfSlope = isNum(
			roadObj.settings.cutSettings.shelfSlope,
		)
			? roadObj.settings.cutSettings.shelfSlope
			: 100;

		roadCommonSettings.cutSetting.right.slopeHeight = isNum(
			roadObj.settings.cutSettings.roadHeight,
		)
			? roadObj.settings.cutSettings.roadHeight
			: 5;
		roadCommonSettings.cutSetting.right.moundSlope = isNum(
			roadObj.settings.cutSettings.moundSlope,
		)
			? roadObj.settings.cutSettings.moundSlope
			: 1;
		roadCommonSettings.cutSetting.right.shelfWidth = isNum(
			roadObj.settings.cutSettings.shelfWidth,
		)
			? roadObj.settings.cutSettings.shelfWidth
			: 1.5;
		roadCommonSettings.cutSetting.right.shelfSlope = isNum(
			roadObj.settings.cutSettings.shelfSlope,
		)
			? roadObj.settings.cutSettings.shelfSlope
			: 100;
	}
	roadCommonSettings.cutSetting.generateShelf =
		roadObj.settings.cutSettings.generateShelf;

	if (roadObj.settings.embarkmentSettings.bLeftRightSettings) {
		//separate left and right settings
		roadCommonSettings.embarkmentSetting.left.slopeHeight = isNum(
			roadObj.settings.embarkmentSettings.leftSlopeHeight,
		)
			? roadObj.settings.embarkmentSettings.leftSlopeHeight
			: 5;
		roadCommonSettings.embarkmentSetting.left.moundSlope = isNum(
			roadObj.settings.embarkmentSettings.leftMoundSlope,
		)
			? roadObj.settings.embarkmentSettings.leftMoundSlope
			: 1;
		roadCommonSettings.embarkmentSetting.left.shelfWidth = isNum(
			roadObj.settings.embarkmentSettings.leftShelfWidth,
		)
			? roadObj.settings.embarkmentSettings.leftShelfWidth
			: 1.5;
		roadCommonSettings.embarkmentSetting.left.shelfSlope = isNum(
			roadObj.settings.embarkmentSettings.leftShelfSlope,
		)
			? roadObj.settings.embarkmentSettings.leftShelfSlope
			: 100;

		roadCommonSettings.embarkmentSetting.right.slopeHeight = isNum(
			roadObj.settings.embarkmentSettings.rightSlopeHeight,
		)
			? roadObj.settings.embarkmentSettings.rightSlopeHeight
			: 5;
		roadCommonSettings.embarkmentSetting.right.moundSlope = isNum(
			roadObj.settings.embarkmentSettings.rightMoundSlope,
		)
			? roadObj.settings.embarkmentSettings.rightMoundSlope
			: 1;
		roadCommonSettings.embarkmentSetting.right.shelfWidth = isNum(
			roadObj.settings.embarkmentSettings.rightShelfWidth,
		)
			? roadObj.settings.embarkmentSettings.rightShelfWidth
			: 1.5;
		roadCommonSettings.embarkmentSetting.right.shelfSlope = isNum(
			roadObj.settings.embarkmentSettings.rightShelfSlope,
		)
			? roadObj.settings.embarkmentSettings.rightShelfSlope
			: 100;
	} else {
		roadCommonSettings.embarkmentSetting.left.slopeHeight = isNum(
			roadObj.settings.embarkmentSettings.roadHeight,
		)
			? roadObj.settings.embarkmentSettings.roadHeight
			: 5;
		roadCommonSettings.embarkmentSetting.left.moundSlope = isNum(
			roadObj.settings.embarkmentSettings.moundSlope,
		)
			? roadObj.settings.embarkmentSettings.moundSlope
			: 1;
		roadCommonSettings.embarkmentSetting.left.shelfWidth = isNum(
			roadObj.settings.embarkmentSettings.shelfWidth,
		)
			? roadObj.settings.embarkmentSettings.shelfWidth
			: 1.5;
		roadCommonSettings.embarkmentSetting.left.shelfSlope = isNum(
			roadObj.settings.embarkmentSettings.shelfSlope,
		)
			? roadObj.settings.embarkmentSettings.shelfSlope
			: 100;

		roadCommonSettings.embarkmentSetting.right.slopeHeight = isNum(
			roadObj.settings.embarkmentSettings.roadHeight,
		)
			? roadObj.settings.embarkmentSettings.roadHeight
			: 5;
		roadCommonSettings.embarkmentSetting.right.moundSlope = isNum(
			roadObj.settings.embarkmentSettings.moundSlope,
		)
			? roadObj.settings.embarkmentSettings.moundSlope
			: 1;
		roadCommonSettings.embarkmentSetting.right.shelfWidth = isNum(
			roadObj.settings.embarkmentSettings.shelfWidth,
		)
			? roadObj.settings.embarkmentSettings.shelfWidth
			: 1.5;
		roadCommonSettings.embarkmentSetting.right.shelfSlope = isNum(
			roadObj.settings.embarkmentSettings.shelfSlope,
		)
			? roadObj.settings.embarkmentSettings.shelfSlope
			: 100;
	}
	roadCommonSettings.embarkmentSetting.generateShelf =
		roadObj.settings.embarkmentSettings.generateShelf;

	//segment setting
	//UI has segment setting for the group
	//Viewer has segment setting for individual segment
	const getViewerSegmentSetting = (index) => {
		const commonSetting = JSON.parse(JSON.stringify(roadCommonSettings));
		const segSetting = roadObj.settings.commonSettings.segmentSettings.find(
			(s) => index >= s.startIndex && index <= s.endIndex,
		);
		if (segSetting) {
			commonSetting.path.roadSlopeType = segSetting.slopeType;
			if (commonSetting.path.roadSlopeType === "straight") {
				commonSetting.path.slope = segSetting.slope;
			} else if (commonSetting.path.roadSlopeType === "cross") {
				commonSetting.path.rightSlope = segSetting.slope;
				commonSetting.path.leftSlope = segSetting.slope;
			}
		}
		return commonSetting;
	};

	const viewerSegmentSettings = roadData.roadSettings.segmentSettings;
	for (const setting of viewerSegmentSettings) {
		setting.commonSetting = getViewerSegmentSetting(setting.startIndex);
	}

	return roadData;
};

export const updateRoadSettingsInViewer = async (roadId, roadObj) => {
	const roadData = updateRoadCommonSettings(roadId, roadObj);
	await window["viewer"].updateRoadSettings(roadId, roadData.roadSettings);

	const lengthOfRoad = window["viewer"].getLengthOfRoad(roadObj.id);
	if (lengthOfRoad) {
		roadObj.info.length = Number.parseFloat(lengthOfRoad.toFixed(3));
	}
	roadObj.info.height = window["viewer"].getRoadHeightInfo(roadObj.id);
	roadObj.info.slope = window["viewer"].getRoadSlopeInfo(roadObj.id);
};

export const updateTrenchSettingsInViewer = async (trenchId, trenchObj) => {
	const isNum = (val) => {
		return typeof val == "number";
	};
	const trenchDataJSONStr = trenchObj.json
		? trenchObj.json
		: window["viewer"].getTrenchJSON(trenchId);
	const trenchData = JSON.parse(trenchDataJSONStr);

	const trenchCommonSettings = trenchData.trenchSettings.commonSetting;
	//common settings
	//床掘の左右を廃止。UI上は床掘幅として入力しそれを二分した値をviewerへ渡す
	trenchCommonSettings.path.leftWidth =
		(isNum(trenchObj.settings.commonSettings.width)
			? trenchObj.settings.commonSettings.width
			: 1.5) / 2;
	trenchCommonSettings.path.rightWidth =
		(isNum(trenchObj.settings.commonSettings.width)
			? trenchObj.settings.commonSettings.width
			: 1.5) / 2;
	trenchCommonSettings.path.leftSlope = isNum(
		trenchObj.settings.commonSettings.leftSlope,
	)
		? trenchObj.settings.commonSettings.leftSlope
		: 50;
	trenchObj.settings.commonSettings.rightSlope = isNum(
		trenchObj.settings.commonSettings.leftSlope,
	)
		? trenchObj.settings.commonSettings.leftSlope
		: 50;
	trenchCommonSettings.path.rightSlope = trenchObj.settings.commonSettings
		.isSuperElevation
		? -1 * trenchObj.settings.commonSettings.rightSlope
		: trenchObj.settings.commonSettings.rightSlope;
	trenchCommonSettings.path.trenchSlopeType = trenchObj.settings.commonSettings
		.isSuperElevation
		? "straight"
		: "cross";
	trenchCommonSettings.path.slope = isNum(
		trenchObj.settings.commonSettings.leftSlope,
	)
		? trenchObj.settings.commonSettings.leftSlope
		: 5;
	trenchCommonSettings.path.interpolationPitch =
		isNum(trenchObj.settings.commonSettings.interpolationPitch) &&
		trenchObj.settings.commonSettings.interpolationPitch != 0
			? trenchObj.settings.commonSettings.interpolationPitch
			: 5;
	trenchCommonSettings.path.specifiedElevation = isNum(
		trenchObj.settings.commonSettings.specifiedElevation,
	)
		? trenchObj.settings.commonSettings.specifiedElevation
		: 1;

	trenchCommonSettings.path.longitudinalSlope.slopeType =
		trenchObj.settings.commonSettings.longitudinalSlope.slopeType;
	trenchCommonSettings.path.longitudinalSlope.slopeValue =
		trenchObj.settings.commonSettings.longitudinalSlope.slopeValue;
	trenchCommonSettings.path.longitudinalSlope.depth =
		trenchObj.settings.commonSettings.longitudinalSlope.depth;

	trenchCommonSettings.destination = {
		target: trenchObj.settings.commonSettings.destination.target,
		id: trenchObj.settings.commonSettings.destination.id,
		specifiedAltitude:
			trenchObj.settings.commonSettings.destination.specifiedAltitude,
	};

	if (trenchObj.settings.cutSettings.bLeftRightSettings) {
		//separate left and right settings
		trenchCommonSettings.cutSetting.left.slopeHeight = isNum(
			trenchObj.settings.cutSettings.leftSlopeHeight,
		)
			? trenchObj.settings.cutSettings.leftSlopeHeight
			: 5;
		trenchCommonSettings.cutSetting.left.moundSlope = isNum(
			trenchObj.settings.cutSettings.leftMoundSlope,
		)
			? trenchObj.settings.cutSettings.leftMoundSlope
			: 1;
		trenchCommonSettings.cutSetting.left.shelfWidth = isNum(
			trenchObj.settings.cutSettings.leftShelfWidth,
		)
			? trenchObj.settings.cutSettings.leftShelfWidth
			: 1.5;
		trenchCommonSettings.cutSetting.left.shelfSlope = isNum(
			trenchObj.settings.cutSettings.leftShelfSlope,
		)
			? trenchObj.settings.cutSettings.leftShelfSlope
			: 100;

		trenchCommonSettings.cutSetting.right.slopeHeight = isNum(
			trenchObj.settings.cutSettings.rightSlopeHeight,
		)
			? trenchObj.settings.cutSettings.rightSlopeHeight
			: 5;
		trenchCommonSettings.cutSetting.right.moundSlope = isNum(
			trenchObj.settings.cutSettings.rightMoundSlope,
		)
			? trenchObj.settings.cutSettings.rightMoundSlope
			: 1;
		trenchCommonSettings.cutSetting.right.shelfWidth = isNum(
			trenchObj.settings.cutSettings.rightShelfWidth,
		)
			? trenchObj.settings.cutSettings.rightShelfWidth
			: 1.5;
		trenchCommonSettings.cutSetting.right.shelfSlope = isNum(
			trenchObj.settings.cutSettings.rightShelfSlope,
		)
			? trenchObj.settings.cutSettings.rightShelfSlope
			: 100;
	} else {
		trenchCommonSettings.cutSetting.left.slopeHeight = isNum(
			trenchObj.settings.cutSettings.trenchHeight,
		)
			? trenchObj.settings.cutSettings.trenchHeight
			: 5;
		trenchCommonSettings.cutSetting.left.moundSlope = isNum(
			trenchObj.settings.cutSettings.moundSlope,
		)
			? trenchObj.settings.cutSettings.moundSlope
			: 1;
		trenchCommonSettings.cutSetting.left.shelfWidth = isNum(
			trenchObj.settings.cutSettings.shelfWidth,
		)
			? trenchObj.settings.cutSettings.shelfWidth
			: 1.5;
		trenchCommonSettings.cutSetting.left.shelfSlope = isNum(
			trenchObj.settings.cutSettings.shelfSlope,
		)
			? trenchObj.settings.cutSettings.shelfSlope
			: 100;

		trenchCommonSettings.cutSetting.right.slopeHeight = isNum(
			trenchObj.settings.cutSettings.trenchHeight,
		)
			? trenchObj.settings.cutSettings.trenchHeight
			: 5;
		trenchCommonSettings.cutSetting.right.moundSlope = isNum(
			trenchObj.settings.cutSettings.moundSlope,
		)
			? trenchObj.settings.cutSettings.moundSlope
			: 1;
		trenchCommonSettings.cutSetting.right.shelfWidth = isNum(
			trenchObj.settings.cutSettings.shelfWidth,
		)
			? trenchObj.settings.cutSettings.shelfWidth
			: 1.5;
		trenchCommonSettings.cutSetting.right.shelfSlope = isNum(
			trenchObj.settings.cutSettings.shelfSlope,
		)
			? trenchObj.settings.cutSettings.shelfSlope
			: 100;
	}
	trenchCommonSettings.cutSetting.generateShelf =
		trenchObj.settings.cutSettings.generateShelf;

	//segment setting
	//UI has segment setting for the group
	//Viewer has segment setting for individual segment
	const getViewerSegmentSetting = (index) => {
		const commonSetting = JSON.parse(JSON.stringify(trenchCommonSettings));
		const segSetting = trenchObj.settings.commonSettings.segmentSettings.find(
			(s) => index >= s.startIndex && index <= s.endIndex,
		);
		if (segSetting) {
			commonSetting.path.trenchSlopeType = segSetting.slopeType;
			if (commonSetting.path.trenchSlopeType === "straight") {
				commonSetting.path.slope = segSetting.slope;
			} else if (commonSetting.path.trenchSlopeType === "cross") {
				commonSetting.path.rightSlope = segSetting.slope;
				commonSetting.path.leftSlope = segSetting.slope;
			}
		}
		return commonSetting;
	};

	const viewerSegmentSettings = trenchData.trenchSettings.segmentSettings;
	for (const setting of viewerSegmentSettings) {
		setting.commonSetting = getViewerSegmentSetting(setting.startIndex);
	}
	await window["viewer"].updateTrenchSettings(
		trenchId,
		trenchData.trenchSettings,
	);

	const lengthOfTrench = window["viewer"].getLengthOfTrench(trenchObj.id);
	if (lengthOfTrench) {
		trenchObj.info.length = Number.parseFloat(lengthOfTrench.toFixed(3));
	}
	trenchObj.info.height = window["viewer"].getTrenchHeightInfo(trenchObj.id);
	trenchObj.info.slope = window["viewer"].getTrenchSlopeInfo(trenchObj.id);
	trenchObj.isLoaded &&
		uploadObjAndNotify(trenchObj, constants.objectType.TRENCH);
};

export const toggleAssociatedPointCloudVisibility = async (pointCloudId) => {
	// 摺り付け先の点群 もしくは 擦り付け先の点群以外で表示中になっている点群を抽出する
	const filterPointCloudList = store.getters.pointCloudAndDesignList.filter(
		(item) => {
			return (
				item.getAssetIdNum() === pointCloudId ||
				(item.getAssetIdNum() !== pointCloudId && item.getVisibility())
			);
		},
	);

	const funcArray = filterPointCloudList.map((item) => {
		// 擦り付け先の点群に対する処理
		if (item.getAssetIdNum() === pointCloudId) {
			return async () => {
				// 非表示の場合表示状態に切り替える
				if (!item.getVisibility()) {
					if (item instanceof PointCloud)
						await togglePointCloudVisibility(item, store.getters.getRoads);
					else if (item instanceof Design) await toggleDesignVisibility(item);
				} else {
					// 表示済みの場合フォーカスする
					zoomToTileset(item);
				}
			};
		} else {
			// 擦り付け先の点群以外への処理
			return async () => {
				if (item instanceof PointCloud)
					await togglePointCloudVisibility(item, store.getters.getRoads);
				else if (item instanceof Design) await toggleDesignVisibility(item);
			};
		}
	});
	// 点群の表示切り替え処理を実行
	await Promise.all(funcArray.map((func) => func()));
};

export const updatePointCloudAssociatedWithObj = async (
	objId,
	pointCloudId,
	objType,
) => {
	await toggleAssociatedPointCloudVisibility(pointCloudId);
	const pointCloud = store.getters.pointCloudAndDesignList.find(
		(item) => item.getAssetIdNum() === pointCloudId,
	);
	// 摺り付け先を更新
	if (objType === constants.objectType.ROAD) {
		await window["viewer"].updatePointCloudAssociatedWithRoad(
			objId,
			pointCloud.getAssetIdNum(),
		);
	} else {
		await window["viewer"].updatePointCloudAssociatedWithFlatGround(
			objId,
			pointCloud.getAssetIdNum(),
		);
	}
};

export const updateFlatGroundSettingsInViewer = async (
	flatGroundId,
	flatGroundObj,
) => {
	const isNum = (val) => {
		return typeof val == "number";
	};
	const flatGroundJSONStr = flatGroundObj.json
		? flatGroundObj.json
		: window["viewer"].getFlatGroundJSON(flatGroundId);
	const flatGround = JSON.parse(flatGroundJSONStr);

	flatGround.flatGroundSettings.commonSettings.interpolationPitch = isNum(
		flatGroundObj.settings.commonSettings.interpolationPitch,
	)
		? flatGroundObj.settings.commonSettings.interpolationPitch
		: 5;
	if (flatGround.flatGroundSettings.commonSettings.interpolationPitch <= 0) {
		flatGround.flatGroundSettings.commonSettings.interpolationPitch = 5;
	}
	flatGround.flatGroundSettings.commonSettings.withSlope =
		flatGroundObj.settings.commonSettings.withSlope;

	//Embankment Settings
	flatGround.flatGroundSettings.embankmentSettings.height = isNum(
		flatGroundObj.settings.embankmentSettings.height,
	)
		? flatGroundObj.settings.embankmentSettings.height
		: 5;
	flatGround.flatGroundSettings.embankmentSettings.moundSlope = isNum(
		flatGroundObj.settings.embankmentSettings.moundSlope,
	)
		? flatGroundObj.settings.embankmentSettings.moundSlope
		: 1;
	flatGround.flatGroundSettings.embankmentSettings.shelfWidth = isNum(
		flatGroundObj.settings.embankmentSettings.shelfWidth,
	)
		? flatGroundObj.settings.embankmentSettings.shelfWidth
		: 1.5;
	flatGround.flatGroundSettings.embankmentSettings.shelfSlope = isNum(
		flatGroundObj.settings.embankmentSettings.shelfSlope,
	)
		? flatGroundObj.settings.embankmentSettings.shelfSlope
		: 100;
	flatGround.flatGroundSettings.embankmentSettings.generateShelf =
		flatGroundObj.settings.embankmentSettings.generateShelf;
	flatGround.flatGroundSettings.embankmentSettings.material =
		flatGroundObj.settings.embankmentSettings.color;
	flatGround.flatGroundSettings.embankmentSettings.transparency =
		flatGroundObj.transparency;

	//Cut Settings
	flatGround.flatGroundSettings.cutSettings.height = isNum(
		flatGroundObj.settings.cutSettings.height,
	)
		? flatGroundObj.settings.cutSettings.height
		: 5;
	flatGround.flatGroundSettings.cutSettings.moundSlope = isNum(
		flatGroundObj.settings.cutSettings.moundSlope,
	)
		? flatGroundObj.settings.cutSettings.moundSlope
		: 1;
	flatGround.flatGroundSettings.cutSettings.shelfWidth = isNum(
		flatGroundObj.settings.cutSettings.shelfWidth,
	)
		? flatGroundObj.settings.cutSettings.shelfWidth
		: 1.5;
	flatGround.flatGroundSettings.cutSettings.shelfSlope = isNum(
		flatGroundObj.settings.cutSettings.shelfSlope,
	)
		? flatGroundObj.settings.cutSettings.shelfSlope
		: 100;
	flatGround.flatGroundSettings.cutSettings.generateShelf =
		flatGroundObj.settings.cutSettings.generateShelf;
	flatGround.flatGroundSettings.cutSettings.material =
		flatGroundObj.settings.cutSettings.color;
	flatGround.flatGroundSettings.cutSettings.transparency =
		flatGroundObj.transparency;

	await window["viewer"].updateFlatGroundSettings(
		flatGroundId,
		flatGround.flatGroundSettings,
	);
	const maxHeight =
		window["viewer"].getMaxHeightOfFlatGroundCtrlPts(flatGroundId);
	flatGroundObj.info.maxHeight = maxHeight.toFixed(3);
};

/**
 * 線形の表示ステータスを切り替える
 * @param {*} entity
 */
export const toggleLinearVisibility = async (entity) => {
	const type = "linear";
	if (!entity.isLinearVisible) {
		const assetId = Number(store.getters.visiblePointCloudList[0].getAssetId());
		// 前回表示したものと異なる点群に表示しようとした場合、現在表示されている線形、距離、断面を削除
		if (entity.assetId != assetId) {
			deleteMeasurement(entity);
		}
		if (entity.isLinearLoaded) {
			// 読み込み済みの場合は表示ステータスのみ切り替え
			await window["viewer"].showMeasurement(
				String(entity.measurementId),
				type,
			);
			entity.isLinearVisible = true;
		} else {
			// 未読み込みの場合はviewerに読み込む
			await window["viewer"].loadMeasurement(
				String(entity.measurementId),
				type,
				assetId,
				{
					start: entity.startPoints,
					end: entity.endPoints,
				},
			);
			await window["viewer"].showMeasurement(
				String(entity.measurementId),
				type,
			);
			entity.isLinearVisible = true;
			entity.isLinearLoaded = true;
			entity.assetId = assetId;
		}
	} else {
		window["viewer"].hideMeasurement(String(entity.measurementId), type);
		entity.isLinearVisible = false;
	}
	return;
};

/**
 * 距離の表示ステータスを切り替える
 * @param {*} entity
 */
export const toggleDistanceVisibility = async (entity) => {
	const type = "distance";
	if (!entity.isDistanceVisible) {
		const assetId = Number(store.getters.visiblePointCloudList[0].getAssetId());
		// 前回表示したものと異なる点群に表示しようとした場合、現在表示されている線形、距離、断面を削除
		if (entity.assetId != assetId) {
			deleteMeasurement(entity);
		}
		if (entity.isDistanceLoaded) {
			// 読み込み済みの場合は表示ステータスのみ切り替え
			await window["viewer"].showMeasurement(
				String(entity.measurementId),
				type,
			);
			entity.isDistanceVisible = true;
		} else {
			// 未読み込みの場合はviewerに読み込む
			await window["viewer"].loadMeasurement(
				String(entity.measurementId),
				type,
				assetId,
				{
					start: entity.startPoints,
					end: entity.endPoints,
				},
			);
			await window["viewer"].showMeasurement(
				String(entity.measurementId),
				type,
			);
			entity.isDistanceVisible = true;
			entity.isDistanceLoaded = true;
			entity.assetId = assetId;
		}
	} else {
		window["viewer"].hideMeasurement(String(entity.measurementId), type);
		entity.isDistanceVisible = false;
	}
	return;
};

/**
 * 断面の表示ステータスを切り替える
 * @param {*} entity
 */
export const toggleCrossSectionVisibility = async (entity) => {
	const type = "crossSection";
	if (!entity.isCrossSectionVisible) {
		const assetId = Number(store.getters.visiblePointCloudList[0].getAssetId());
		// 前回表示したものと異なる点群に表示しようとした場合、現在表示されている線形、距離、断面を削除
		if (entity.assetId != assetId) {
			deleteMeasurement(entity);
		}
		if (entity.isCrossSectionLoaded) {
			// 読み込み済みの場合は表示ステータスのみ切り替え
			await showMeasurementCrossSection(entity);
			entity.isCrossSectionVisible = true;
		} else {
			// 未読み込みの場合はviewerに読み込む
			await window["viewer"].loadMeasurement(
				String(entity.measurementId),
				type,
				assetId,
				{
					start: entity.startPoints,
					end: entity.endPoints,
				},
			);
			await showMeasurementCrossSection(entity);
			entity.isCrossSectionVisible = true;
			entity.isCrossSectionLoaded = true;
			entity.assetId = assetId;
		}
	} else {
		hideMeasurementCrossSection(entity);
		entity.isCrossSectionVisible = false;
	}
	return;
};

/**
 * 2点と紐づく線形、距離、断面をviewer上から削除する
 * @param {*} entity
 */
export const deleteMeasurement = (entity) => {
	const types = ["linear", "distance", "crossSection"];
	for (const type of types) {
		// 非表示にする
		if (type === "crossSection") {
			hideMeasurementCrossSection(entity);
		} else {
			window["viewer"].hideMeasurement(String(entity.measurementId), type);
		}
	}
	// 削除実行
	window["viewer"].deleteMeasurement(String(entity.measurementId));
	entity.reset();
};

export const showMeasurementCrossSection = async (measurement) => {
	const type = "crossSection";
	await window["viewer"].showMeasurement(
		String(measurement.measurementId),
		type,
	);
	store.dispatch("measurement/setActiveArbitraryCrossSection", measurement);
};

export const hideMeasurementCrossSection = async (measurement) => {
	const type = "crossSection";
	window["viewer"].hideMeasurement(String(measurement.measurementId), type);
	store.dispatch("measurement/setActiveArbitraryCrossSection", null);
};

export const getCornerPositions = (
	latitude,
	longitude,
	imageWidth,
	imageHeight,
	rotateDegree,
) => {
	const cornerPositions = window["viewer"].getCornerPositions(
		Number(latitude),
		Number(longitude),
		Number(imageWidth),
		Number(imageHeight),
		Number(rotateDegree),
	);
	return cornerPositions;
};

export const pickPositions = async (numberOfPositionToPick) => {
	const positions = store.getters.viewer.pickPointsForRoadConstruction_AI(
		numberOfPositionToPick,
	);
	return positions;
};
