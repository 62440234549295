<script setup>
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Drawing2dImage } from "../../models/Drawing2DImage";
import { removeDrawing2dImage } from "../../utils/cesium-common";

/** props定義 */
const props = defineProps({
	entity: Drawing2dImage,
	draggable: Boolean,
});

/** 関数定義 */
const store = useStore();
const { t } = useI18n();
const route = useRoute();

const remove = async () => {
	try {
		await store.dispatch("drawing2dImage/deleteDrawing2dImage", {
			siteId: route.query.siteId,
			drawing2dImage: props.entity,
		});

		removeDrawing2dImage(props.entity);

		store.commit("set_snackbar", {
			text: `${props.entity.getName()} ${t("REMOVE")} ${t("successful")}`,
			color: "rgba(0, 153, 0, 0.72)",
		});
	} catch (e) {
		store.commit("set_snackbar", {
			text: `${t("REMOVE")} ${t("failed")}`,
			color: "rgba(153, 0, 0, 0.72)",
		});
		console.error(e);
		return;
	}
};
</script>

<template>
  <span>
    <span v-if="timeStamp" class="ml-4">{{ timeStamp }}</span>
    <v-card-actions class="py-0" :style="isEditOnProgress ? 'background-color: #0064BA;' : ''">
      <v-btn v-if="draggable" icon size="24" class="handle" style="cursor: grab">
        <v-icon> mdi-drag </v-icon>
      </v-btn>
      <v-btn icon size="24" @click="toggle_visibility" :disabled="isDisabledVisibilityBtn" class="ml-0">
        <v-icon>
          {{ entity.getIsVisible() ? "icon:View" : "icon:View-Off" }}
        </v-icon>
      </v-btn>
      <v-btn icon size="36" @click="toggle_expansion" class="ml-0">
        <v-icon>
          mdi-play {{ entity.getExpansion() ? "mdi-rotate-90" : "" }}
        </v-icon>
      </v-btn>
      <ListItemLabel :text="entity?.getName() ?? ''" />
      <v-btn @click="focus" :disabled="isDisabledFocusBtn" icon size="24">
        <v-icon size="small">icon:Search</v-icon>
      </v-btn>
      <v-menu transition="slide-y-transition" :disabled="entity.getTilingProgress()">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props" size="24">
            <v-icon> icon:Overflow-Menu-Vertical </v-icon>
          </v-btn>
        </template>
        <v-list density="compact">
          <v-list-item @click="edit">
            <v-icon class="mr-4">icon:Pencil</v-icon>
            <span style="font-size: 13px">{{ $t("EDIT") }}</span>
          </v-list-item>
          <v-list-item @click="remove">
            <v-icon class="mr-4">mdi-delete</v-icon>
            <span style="font-size: 13px">{{ $t("REMOVE") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- 処理ステータス -->
      <div v-if="entity.getProcessingStatus()">
        <processing-status :status="entity.getProcessingStatus()"
          :processingStatus="constants.pointCloudProcessingStatus">
        </processing-status>
      </div>
    </v-card-actions>
    <div class="pl-7" v-show="entity.getExpansion()">
      <v-card-actions class="py-0 px-5">
        {{ $t("OPACITY") }}
        <v-slider class="ml-1" v-model="transparency" :disabled="isDisabledTransparency" @end="saveSettings"
          hide-details max="1" step="0.01" color="primary" track-size="0.5" thumb-size="14"></v-slider>
      </v-card-actions>
    </div>
  </span>
</template>

<script>
import * as cesiumCommon from "@/utils/cesium-common";
import ProgressCircle from "./ProgressCircle";
import * as constants from "@/constant.js";
import ProcessingStatus from "@/components/common/ProcessingStatus.vue";
import ListItemLabel from "@/components/Project/ListItemLabel.vue";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
// import axios from 'axios';
dayjs.extend(isSameOrAfter);

export default {
  name: "Drawing2dImageListItem",
  components: {
    ProgressCircle,
    ProcessingStatus,
    ListItemLabel
  },
  mounted() {
  },
  data() {
    return {
      showUploadProgress: true,
      isUploadError: false,
      constants,
      isEditOnProgress: false,
    };
  },
  computed: {
    isAssetUploaded() {
      return this.entity.getIsAssetUploaded();
    },
    /**
     * 画面のクリエーションモードの状態
     */
    inCreationMode() {
      return this.$store.state.modes.inCreationMode.isActive;
    },
    /**
     * 点群の透明度
     */
    transparency: {
      get() {
        return this.entity.getTransparency();
      },
      set(value) {
        this.entity.setTransparency(value);
      },
    },
    /**
     * 可視状態切り替えボタンの活性状態を制御する
     * 以下の場合非活性
     * ・道路追加や平場追加を実行中で他の点群が活性状態じゃない
     * ・タイリング途中
     * ・アセットがアップロードされていない
     */
    isDisabledVisibilityBtn() {
      return (
        (this.inCreationMode && !this.entity.getIsCreating()) ||
        this.entity.getTilingProgress() ||
        this.isAssetUploaded === "PENDING" ||
        this.isAssetUploaded === "ERROR"
      );
    },
    /**
     * 透明度スライダーの活性状態を制御する
     */
    isDisabledTransparency() {
      // 非表示状態の場合は非活性
      return !this.entity.getIsVisible();
    },
    /**
     * フォーカスボタンの活性状態を制御する
     */
    isDisabledFocusBtn() {
      // 非表示状態の場合は非活性
      return !this.entity.getIsVisible();
    },
    timeStamp() {
      return this.entity.getTimeStamp();
    },
  },
  watch: {
    // アップロードステータスがPENDINGになれば再度ポーリング開始
    isAssetUploaded(newValue) {
      if (newValue === "PENDING") {
        this.showUploadProgress = true;
        this.isUploadError = false;
      }
    },
    transparency() {
      let transparency_val = parseFloat(this.transparency.toFixed(2));
      try {
        cesiumCommon.updateDrawing2dImageTransparency(
          this.entity,
          transparency_val
        );
      } catch (e) {
        console.error(e);
      }
    },
    inCreationMode(newCreationMode) {
      if (newCreationMode) {
        // 画面がクリエーションモードに変更になった場合
        if (this.entity.getVisibility()) {
          // 可視状態であれば点群をクリエーションモードに変更
          this.entity.setIsCreating(true);
        }
      } else {
        // 画面のクリエーションモードが解除された場合
        if (this.entity.getIsCreating()) {
          // 点群がクリエーションモードであれば解除
          this.entity.setIsCreating(false);
        }
      }
    },
  },
  methods: {
    async toggle_visibility() {
      await cesiumCommon.toggleDrawing2dImageVisibility(this.entity);
    },
    toggle_expansion() {
      this.entity.setExpansion(!this.entity.getExpansion());
    },
    focus() {
      cesiumCommon.zoomToDrawing2dImage(this.entity);
    },
    saveSettings() {
      console.log("save settings. Call Put API with file id and transparency value");
      // Todo: implement backend API
      axios.put(
        `${import.meta.env.VITE_API_BASE}/drawing2dImage/setting/${this.$route.query.siteId}/${this.entity.getId()}`,
        {
          transparency: this.transparency
        }
      )
    },
    edit() {
      this.isEditOnProgress = !this.isEditOnProgress;
      if (this.isEditOnProgress) {
        this.$store.getters.viewer.start2PointsAlignment(this.entity.getId(), {
          onError: (e) => {
            console.warn(e);
            this.$store.commit("set_snackbar", {
              text: `${this.$t("EDIT")} ${this.$t("failed")}`,
              color: "rgba(153, 0, 0, 0.72)",
            });
          },
          onCancel: () => {
            this.isEditOnProgress = false;
          }

        });
      }
      else {
        this.$store.getters.viewer.end2PointsAlignment();
      }
    }
  },
  beforeUnmount() {
    // 仮設道路追加や平場追加を行っている場合キャンセル
    if (this.inCreationMode) {
      window.dispatchEvent(new Event("cancelCreateMode"));
    }
  },
};
</script>
